import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import Sections from '../components/Sections';
import { useSuccessStories } from '../queries/useSuccessStories';
import SuccessStoryItem from '../components/SuccessStories/SuccessStoryItem';
import { ContentMain, RoundedButton, device } from '../components/commonStyled';

const StoriesContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -25px;
`;

const SuccessStoryItemStyled = styled(SuccessStoryItem)`
    width: 333px;
    min-width: 260px;
    margin: 68px 25px;
`;

const ShowMoreButton = styled(RoundedButton)`
    font-size: 18px;
    padding: 17px 45px;
    margin-bottom: 24px;
    background-color: #a60424;
    color: #ffffff;

    @media ${device.laptopSmall} {
        align-self: auto;
    }

    @media ${device.tabletMedium} {
        font-size: 16px;

        padding: 14px 24px;
    }
`;

const MAX_SHOW_ITEMS = 6;

export default ({ data, location }) => {
    const {
        title,
        seo,
        common_acf: { blocks },
    } = data?.wpPage;

    const stories = useSuccessStories();

    const [showMore, setShowMore] = useState(false);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <StoriesContainer>
                    {stories
                        .slice(0, showMore ? stories.length : MAX_SHOW_ITEMS)
                        .map((item, i) => (
                            <SuccessStoryItemStyled key={i} item={item} />
                        ))}
                </StoriesContainer>
                {stories.length > MAX_SHOW_ITEMS && !showMore && (
                    <ShowMoreButton onClick={() => setShowMore(true)}>
                        Больше историй
                    </ShowMoreButton>
                )}
            </ContentMain>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: {
                        whiteStyle: true,
                        noButton: true,
                        order: 3,
                    },
                }}
            />
        </PageLayout>
    );
};

export const pageQuery = graphql`
    query SuccessStoriesPage {
        wpPage(common_acf: { id: { eq: "successStories" } }) {
            title
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
        }
    }
`;
